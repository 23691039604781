.subtitle {
    font-weight: bold;
    font-size: 10pt;
    color: #121212;
}

.subtitle_center {
    font-weight: bold;
    font-size: 10pt;
    color: #121212;
    text-align: center;
}

.content {
    font-size: 10pt;
    color: #424242;
}

.caution {
    font-size: 8pt;
    color: #424242;
}

.title_important {
    font-weight: bold;
    color: #ff1493;
    text-align: center;
    font-size: 10pt;
}
