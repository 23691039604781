.videoBasic {
    margin-top: 20px;
    width: 96%;
    height: 50%;
    position: relative;
}

.videoBasic2 {
    width: 100%;
    height: 58%;
    position: relative;
}

#videoHidden {
    margin-top: 20px;
    width: 0%;
    height: 0%;
}

.videoWrapper{
    border-top: 10px solid #e3e3e3;
    border-right: 10px solid #c1c1c1;
        /* 右のborderを指定 */
        border-bottom: 10px solid #a8a8a8;
        /* 下のborderを指定 */
        border-left: 10px solid #dedede;
        /* 左のborderを指定 */
        box-shadow: inset 0 0 12px rgba(227, 233, 203, 0.867);
        /* 内側に影を指定 */
        display: inline-block;
}

.hidden {
    margin-top: 20px;
    width: 0%;
    height: 0%;
}
