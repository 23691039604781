.background {
    width: 100%;
    background-image: linear-gradient(#d1e8ff 0.5px, transparent 0),         linear-gradient(90deg, #d1e8ff 0.5px, transparent 0);
     background-size: 10px 10px;
    background-color: #ccffff;
}

.diagnoseButton{
    background: linear-gradient(#ff5151, #ff0000);
    width:50vw;
    border-radius: 10px;
    border:#dc143c solid
}

.innerText {
    color: #ffff0f;
    font-weight: bolder;
    font-size: 24pt;
}

.quickWrapper {
    position: relative;
    background-color: white;
    width:100%;
    display: inline-block;
}

.quickWrapper:before {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 50%;
    border: 15px solid transparent;
    border-top: 15px solid #e0edff;
}

.normalText {
    color:#424242;
    font-size: 10pt;
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px 5vw 0 1vw;
    font-weight: bold;
}

.footer {
    background-color: #dcdcdc;
    text-align: center;
    font-family: Arial,
        Helvetica,
        sans-serif;
    padding: 12px;
}

.output {
    background-color: #e0ffe0;
    background-image: linear-gradient(#d1e8ff 0.5px, transparent 0), linear-gradient(90deg, #d1e8ff 0.5px, transparent 0);
        background-size: 10px 10px;
    text-align: center;
    font-family: Arial,
        Helvetica,
        sans-serif;
}

