.boardWrapper {
    position:relative;
    width:100vw;
    height:120vw;
    margin-top: 20px;
    text-align: center;
}

.boardImage {
    position:absolute;
    top:10px;
    margin-bottom: 100px;
}

.boardInnerText {
    position: absolute;
    top: 27vw;
    color:#424242;
    text-align: center;
}
