.blockCorrect {
    border-radius: 10px;
    background: linear-gradient(to right, #97e797, #12cc6f);
    padding:6px;
    font-weight: bold;
    color:#f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
}

.blockError {
    border-radius: 10px;
    background-color: #fb5c5c;
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
}

.blockAlert {
    border-radius: 10px;
    background-color: #fbc15c;
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
}

.upStayBlockCorrect {
    border-radius: 10px;
    background: linear-gradient(to right, #198319,#5bc25b ,#5bc25b,#198319);
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    text-align: center;
    font-size: 18pt;
    margin-bottom: -2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
}

.upStayBlockAlert {
    border-radius: 10px;
    background-color: #fbc15c;
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
}

.iconGood {
    font-size: 16pt;
    margin-right:10px;
    width: 30px;
}

.iconExcellent {
    font-size: 16pt;
    margin-right: 10px;
    width: 30px;
    color: #fff352;
    animation: animation-text-good 0.8s linear infinite;
}

.icon {
    font-size: 16pt;
    margin-right: 10px;
    width: 30px;
    /* // animation: animation-text-good 1s linear infinite; */
}

.bottomArrow{
    text-align: left;
    font-size: 24pt;
    border-left: solid;
}

.message {
    text-align: left;
    width: calc(100%-30px);
}

.upStayMessage {
    text-align: center;
}

.cascadingLine {
    border-left: solid #acacac;
    border-bottom: solid #acacac;
    margin-left:20px;
}

.cascadingSpace {
    border-left: solid #acacac;
    margin-left: 20px;
    height: 16px;
    min-height: 10px;
}

@keyframes animation-text-good {
    0% {
        transform: rotateZ(0deg);
    }

    50%{
        transform:rotateZ(-20deg) scale(1.2);
    }

     70% {             transform: rotateZ(0deg);
     }
}
