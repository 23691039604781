.blackBorder {
    margin-top: 10vh;
    padding:12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #424242 solid
}

.joyboyWrapper {
    margin:auto;
    width:80vw;
    height:60vw;
}

.blueButton {
    width:80vw;
    background-color: #0000ff;
    border-radius: 10px;
    padding:12px 0 12px 0;
}

.blackBorderSmall {
    padding: 12px;
    font-size: 12pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #424242 solid
}

.yellowBorder {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ffd700 solid;
    color:#ffbf00;
}

.orangeBorder {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ff9d00 solid;
    color: #ff9d00;
}

.redBorder {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ff6a00 solid;
    color: #ff6200;
}

.modalContentWrapper {
    width:90vw;
    height:60vh;
    overflow-y: scroll;
}
