/* https: //fuuno.net/web/center/center.html */
.disabled {
    width: 22vw;
    height: 18vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffd700;
    box-shadow: 0 1vw 0 #ffa500;
}

.disabled:active {
    transform: translateY(4px);
    box-shadow: 0 0 0 #ffa500;
    transition: .0s;
}

.selected {
    width: 22vw;
    height: 18vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffb6c1;
    box-shadow: 0 1vw 0 #ff69b4;
}

.selected:active {
    transform: translateY(4px);
    box-shadow: 0 0 0 #ff69b4;
    transition: .0s;
}

.rubi {
    font-size: 6pt;
    margin-top: -1px;
}
