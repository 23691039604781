.stripe {
    text-decoration: none;
    color:white;
    font-weight: bold;
}

.boldtitle {
text-decoration: underline,
}

.taikenMessageBox {
    padding:6px
}

.taikenMessageTop {
    font-size: 13pt;
    font-weight: bold;
}

.linkedText {
    color: #4169e1;
    text-decoration: underline;
}

.lp_header {
    position: absolute;
    top:0px;
    /* background-color: rgba(255, 250, 240, 0.45); */
    width:100vw;
    display:flex;
    justify-content: flex-end;
}

.backgroundImage {
    position: relative;
}

.backgroundImage img {
    width: 100%;
}


.programExplanation {
    background-color: #daeaff;
}

.sectionTitle {
    font-weight: bold;
    font-size: 1.4rem;
    color: '#3a567c';
    text-decoration: underline 4px #000080;
}

.schedule {
    font-weight: bold;
    font-size: 1.3rem;
    color: '#3a567c';
    text-decoration: underline 4px #000080;
}

.fixBar {
    position: sticky;
    background-color: rgba(255,250,240,0.8);
    bottom:0px;
}
