.shojoWrapper {
    position: relative;
    align-items: center;
    width: 100vw;
    height: 70vw;
    color:#424242
}

.shojoIllust {
    position: absolute;
    top: 5px;
    width: 100%;
    height: auto}

.header {
    position: absolute;
    margin-top: 16vw;
    width: 100%;
}

.devider {
    width: 80vw;
    border: 1px solid #a9a9a9 ;
    margin-left: 10vw;
    margin-top: 10px;
}

.content {
    width: 76%;
    margin-left: 12vw;
    font-size: 10pt;
}

.ending {
    margin-left: 50vw;
    font-size: 10pt;
}
