
.fixBar {
    position: sticky;
    background-color: rgba(255, 250, 240, 0.8);
    bottom: 0px;
    z-index: 300;
}

.title {
    font-weight: bold;
    color: #404040;
    font-size: 18pt;
    text-decoration: underline 2px;
    text-decoration-color:#4fd3ffc0 ;
    margin-top: 8px;
}

.simpletitle {
    font-weight: bold;
    color: #404040;
    font-size: 18pt;

}

.semititle {
    font-weight: bold;
    color: #404040;
    font-size: 14pt;
    margin-top: 4px;
}

.subtext {
    color: #404040;
    font-size: 10pt;
    margin-top: 4px;
    padding-left: 10px;
}

.appliStrength{
    margin-top: 30px;
    padding: 10px;
    position:relative;
}

.appliStrength2 {
    margin-top: 30px;
    position: relative;
        overflow-x: hidden;
}

.upper{
    z-index: 100;
}

.applicircle {
    position: absolute;
    top:-10px;
    height:120%;
    width:120%;
    border-radius: 50%;
    background-color: rgba(255,255,147,0.5);
    z-index: -1;
    margin-left: -40%;
    overflow-x: hidden;
}

.applicircleRight {
    position: absolute;
    top: -40px;
    height: 100%;
    width: 110%;
    border-radius: 50%;
    background-color: rgba(255, 255, 147, 0.5);
    z-index: -1;
    margin-right: -30%;
}

.salesbackground{
    background-image: linear-gradient(90deg, rgb(253, 171, 170), rgba(240, 226, 226, 1));
    padding:"10px"
}

.underfukidashi{
    position: relative;
        display: inline-block;
        width:100%;
        padding-top:10px;
        padding-bottom: 10px;
        min-width: 120px;
        color: rgb(255, 255, 255);
        font-size: 18px;
        background: #3cb371;
        font-weight: bold;
}

.underfukidashi:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 15px solid #3cb371;
}

.pinkball {
    border-radius: 50%;
    background-color: #ffb6c1;
    color: white;
    font-weight: bold;
    height: 18vw;
    width: 18vw;
    line-height: 18vw;
    margin-left: 12px;
    margin-top:12px;
}

.checknow {
    padding:8px;
    font-size: 16pt;
    color:#424242
}


