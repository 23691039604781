.fab {
    position: fixed; /* 今の位置を基準 */
    bottom: 80px; /* 上から10px */
    right: 8%; /* 左から20px */
}

.modal-overlay.clicked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  /* 一番下のbody領域をぼやかす */
  z-index: 5;
}

.modal-content.clicked {
  z-index: 10;
}

.plus {
    border-radius: 30px;
    width: 50px;
    height: 50px;
    border-color: transparent;
    background-color: #7fbfff;
    box-shadow: 1px 2px 2px 2px rgba(188,211,255,0.8);
}

.plus:active {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}
.plus.clicked {
    background-color: #a4c1d7;
    transition-duration: 0.4s;
}

.plus.clicked::after {
    background-color: #a4c1d7;
    transition-duration: 0.4s;
}

.buttons {
    position: fixed; /* 今の位置を基準 */
    bottom: 150px; /* 上から10px */
    right: 8%; /* 左から20px */
    display: none;
}

.buttons.clicked {
    position: fixed; /* 今の位置を基準 */
    bottom: 150px; /* 上から10px */
    right: 5%; /* 左から20px */
    display: block;
    animation: SlideIn 0.4s;
}

.add-video {
    border-radius: 30px;
    width: 150px;
    height: 50px;
    border-color: transparent;
    background-color: #87ceeb;
    color: white;
    box-shadow: 0 3px 0 #6495ed;
}

.add-video:active {
    background-color: #87ceeb;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}

.try-levelup {
  border-radius: 30px;
  width: 150px;
  height: 50px;
  border-color: transparent;
  background-color: #ff69b4;
  color: white;
  box-shadow: 0 3px 0 #ff1493;
}

.try-levelup:active {
  background-color: #ff69b4;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  transition-duration: 0.4s;
}

.trial {
    border-radius: 30px;
    width: 150px;
    height: 50px;
    border-color: transparent;
    background-color: #3cb371;
    color: white;
    box-shadow: 0 3px 0 #008000;
}

.trial:active {
    background-color: #ff69b4;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}

.workout {
    border-radius: 30px;
    width: 150px;
    height: 50px;
    border-color: transparent;
    background-color: #f39800;
    color: white;
    box-shadow: 1px 3px 0 #ee7800;
}

.workout:active {
    background-color: #ee7800;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}

@keyframes SlideIn {
    0% {
        opacity: 0; /*初期状態では透明に*/
        transform: translateX(64px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
