@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

label.inputwrapper {
    background: #75a9ff;
    color: #fff;
    cursor: pointer;
    padding: 10px 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
}
label input {
    display: none;
}

.textlink {
    color: #75a9ff;
    border-bottom: dashed 1px #75a9ff;
    cursor: pointer;
}

.scrolling-wrapper {
    overflow-y: hidden;
    white-space: nowrap;
}
.scrolling-wrapper .item {
    display: inline-block;
}

.apology {
    border: 1px solid #cccccc;
    font-size: 10pt;
    padding: 6px;
    margin: 10px;
}

.App {
    text-align: center;
}


#accountTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#accountTable th {
    width: 30%;
    font-size: 10pt;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}
#accountTable td {
    width: 50%;

    font-size: 10pt;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}


#videoCard {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
    transition: 0.3s;
    width: 95%;
    text-align: center;
    padding: 5px;
    margin-top: 10px;
    margin-left: 3px;
}
#videoCard:hover {
    box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
}

#rankingTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 90%;
    padding: '15px';
}

#rankingTable th {
    width: 30%;
    font-size: 10pt;
    border-left: 3px solid slategray;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
    background-color: moccasin;
}

#rankingTable td {
    text-align: center;
    font-size: 10pt;
    border-left: 3px solid slategray;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}

.stopwatch_watchWrapper__3gXmj{
    /* position: relative; */
    text-align: center;
    align-items:center;
}

.stopwatch_watchIllust__2Gd0c {
    position: absolute;
    top:5px;
    width: 200px;
    height:200px
}

.stopwatch_watchInner__10b3l {
    /* position: absolute;
    top: 50%; */
    background-color:#1e90ff;
    margin-left:25%;
    width:50%;
    padding:4px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 20px;
}



.timeviewer_formviewer__oFNea {
    margin-top: 6px;
    font-style:italic;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 32pt;
    color:#ff1493;
}

.timeviewer_label__2XzWy{
    border-radius: 30px;
    border: 1px solid;
    width: 95%
}

.timeviewer_labelVeryGood__BRUW0 {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #ff1493;
    font-weight: bold;
    color: white
}

.timeviewer_labelGood__ez3oN {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #3cb371;
    color: white;
    font-weight: bold;
}

.timeviewer_labelNobi__2c0Z4 {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #87cefa;
    font-weight: bold;
    color: white
}

.timeviewer_labelInactive__147Kt {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #d3d3d3;
    color:white
}

.timeviewer_coachComment__2lWt9 {
    border: 2px solid #e5ffe5;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 11pt;
    font-family: "M PLUS Rounded 1c";
    line-height: 28px;
    padding: 3px 3px 3px 10px ;
    background-color: #e5ffe5;
    position: relative;
}

.timeviewer_coachComment__2lWt9::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 2%;
    left: -11px;
    border-top: 11px solid transparent;
    border-right: 11px solid #e5ffe5;
    border-bottom: 11px solid transparent;
}

.timeviewer_muraiIcon__uUEh9 {
    width: 95%;
    border-radius: 50%;
    /* animation: murai-speak 1.2s linear infinite; */
}

.timeviewer_coachComment2__1lj9T {
    border: 2px solid #e5ffe5;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 11pt;
    font-family: "M PLUS Rounded 1c";
    line-height: 28px;
    padding: 3px 3px 3px 10px;
    background-color: #e5ffe5;
    position: relative;
    text-align: center;
}

.timeviewer_coachComment2__1lj9T::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 2%;
    left: -11px;
    border-top: 11px solid transparent;
    border-right: 11px solid #e5ffe5;
    border-bottom: 11px solid transparent;
}

.registerpage_registerWrapper__2uI5x{
    position:relative;
    text-align: center;
}

.registerpage_registerPage__3IEZy{
    position: absolute;
    top: 1px;
}

.registerpage_registerBack__3p6s4 {
    position: absolute;
    top: 1px;
    z-index: -10;
}

.carousel .thumbs-wrapper {
    margin: 0px;
    overflow: hidden;
}


.MuiBox-root {
    padding: 4px !important;
}

.menuText {
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 10pt;
}

.menuText .menu-0 {
    background-color: #f6fac3;
    padding: 7px;
    min-height: 60px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
}

.menuText .menu-1 {
    background-color: #b5f7bb;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

.menuText .menu-2 {
    background-color: #81ccf7f6;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

.menuText .menu-3 {
    background-color: #b3a0f7;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

.menuText .menu-4 {
    background-color: #ddaef8;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

/* これが最強の装飾方法！！！ */
span.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate.MuiMobileStepper-progress {
    position: relative;
    overflow: hidden;
    display: block;
    height: 4px;
    z-index: 0;
    background-color: rgb(167, 202, 237);
    width: 90%;
}

.stepFunctions_blockCorrect__30Clx {
    border-radius: 10px;
    background: linear-gradient(to right, #97e797, #12cc6f);
    padding:6px;
    font-weight: bold;
    color:#f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
}

.stepFunctions_blockError__2a21H {
    border-radius: 10px;
    background-color: #fb5c5c;
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
}

.stepFunctions_blockAlert__1Jl-p {
    border-radius: 10px;
    background-color: #fbc15c;
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
}

.stepFunctions_upStayBlockCorrect__1v0-R {
    border-radius: 10px;
    background: linear-gradient(to right, #198319,#5bc25b ,#5bc25b,#198319);
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    text-align: center;
    font-size: 18pt;
    margin-bottom: -2px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0);
}

.stepFunctions_upStayBlockAlert__3-yM4 {
    border-radius: 10px;
    background-color: #fbc15c;
    padding: 6px;
    font-weight: bold;
    color: #f5f5f5;
    font-size: 14pt;
    margin-bottom: -2px;
    display: flex;
}

.stepFunctions_iconGood__3DROJ {
    font-size: 16pt;
    margin-right:10px;
    width: 30px;
}

.stepFunctions_iconExcellent__vOuCo {
    font-size: 16pt;
    margin-right: 10px;
    width: 30px;
    color: #fff352;
    -webkit-animation: stepFunctions_animation-text-good__27-O6 0.8s linear infinite;
            animation: stepFunctions_animation-text-good__27-O6 0.8s linear infinite;
}

.stepFunctions_icon__kE-_c {
    font-size: 16pt;
    margin-right: 10px;
    width: 30px;
    /* // animation: animation-text-good 1s linear infinite; */
}

.stepFunctions_bottomArrow__31C89{
    text-align: left;
    font-size: 24pt;
    border-left: solid;
}

.stepFunctions_message__3UBxP {
    text-align: left;
    width: calc(100%-30px);
}

.stepFunctions_upStayMessage__2LRiA {
    text-align: center;
}

.stepFunctions_cascadingLine__2eq3T {
    border-left: solid #acacac;
    border-bottom: solid #acacac;
    margin-left:20px;
}

.stepFunctions_cascadingSpace__2CIa1 {
    border-left: solid #acacac;
    margin-left: 20px;
    height: 16px;
    min-height: 10px;
}

@-webkit-keyframes stepFunctions_animation-text-good__27-O6 {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }

    50%{
        -webkit-transform:rotateZ(-20deg) scale(1.2);
                transform:rotateZ(-20deg) scale(1.2);
    }

     70% {             -webkit-transform: rotateZ(0deg);             transform: rotateZ(0deg);
     }
}

@keyframes stepFunctions_animation-text-good__27-O6 {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }

    50%{
        -webkit-transform:rotateZ(-20deg) scale(1.2);
                transform:rotateZ(-20deg) scale(1.2);
    }

     70% {             -webkit-transform: rotateZ(0deg);             transform: rotateZ(0deg);
     }
}

.videoPlayer_videoBasic__3n6Z1 {
    margin-top: 20px;
    width: 96%;
    height: 50%;
    position: relative;
}

.videoPlayer_videoBasic2__3dNLE {
    width: 100%;
    height: 58%;
    position: relative;
}

#videoPlayer_videoHidden__jPzxE {
    margin-top: 20px;
    width: 0%;
    height: 0%;
}

.videoPlayer_videoWrapper__2C6x3{
    border-top: 10px solid #e3e3e3;
    border-right: 10px solid #c1c1c1;
        /* 右のborderを指定 */
        border-bottom: 10px solid #a8a8a8;
        /* 下のborderを指定 */
        border-left: 10px solid #dedede;
        /* 左のborderを指定 */
        box-shadow: inset 0 0 12px rgba(227, 233, 203, 0.867);
        /* 内側に影を指定 */
        display: inline-block;
}

.videoPlayer_hidden__3aePQ {
    margin-top: 20px;
    width: 0%;
    height: 0%;
}

.coachComment_coachComment__3FKuD {
    border: 2px solid #fafdff;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 11pt;
    font-family: "M PLUS Rounded 1c";
    line-height: 28px;
    padding: 3px;
    background-color: #fafdff;
    position: relative;
}

.coachComment_coachComment__3FKuD::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 2%;
    left: -11px;
    border-top: 11px solid transparent;
    border-right: 11px solid #fafdff;
    border-bottom: 11px solid transparent;
}

.coachComment_kansou__3sg1W {
    min-height: 30px;
    border-radius: 11px;
    background-color: #fafdff;
    text-align: left;
    position: relative;
    padding: 8px;
    font-size: 12pt;
    width: 92%;
}

.coachComment_kansou__3sg1W::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: -8px;
    border-top: 8px solid transparent;
    border-left: 8px solid #fafdff;
    border-bottom: 8px solid transparent;
}

.coachComment_muraiIcon__31wc1{
    width:95%;
    border-radius: 50%;
    -webkit-animation: coachComment_murai-speak__1bIY7 1.2s linear infinite;
            animation: coachComment_murai-speak__1bIY7 1.2s linear infinite;
}

@-webkit-keyframes  coachComment_murai-speak__1bIY7 {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }

    20% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    30% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    60% {
            -webkit-transform: scale(1.1);
                    transform: scale(1.1);
        }
        70% {
            -webkit-transform: scale(1.0);
                    transform: scale(1.0);
        }
    100% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
         }
}

@keyframes  coachComment_murai-speak__1bIY7 {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }

    20% {
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
    }

    30% {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    60% {
            -webkit-transform: scale(1.1);
                    transform: scale(1.1);
        }
        70% {
            -webkit-transform: scale(1.0);
                    transform: scale(1.0);
        }
    100% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
         }
}

th.date {
    font-size: 20;
}

td {
    border: solid 2px rgb(255, 255, 255); /* 枠線指定 */
    padding: 2px;
}

table.calendar {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

tr.dateCell {
    height: 13%;
    background-color: #bbeaff;
}

td.empty-day {
    background-color: rgb(230, 233, 235);
}

.outline {
    display: inline-block;
    color: #c00;
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    border: 2px solid #c00 !important;
    background-color: rgb(233, 231, 231);

    border-radius: 50%;
    padding: 3px;
    margin: 3px;
}

.outline-gray {
    display: inline-block;
    color: rgb(235, 248, 253);
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    border: 2px solid rgb(235, 248, 253) !important;
    border-radius: 50%;
    padding: 3px;
    margin: 3px;
}

.outline-red {
    display: inline-block;
    color: rgb(221, 48, 48);
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    border: 2px solid rgb(232, 102, 102) !important;
    border-radius: 50%;
    padding: 4px;
    margin: 3px;
}

/* 以下モーダル内に表示されるもの */

#videoCardByDay {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
  transition: 0.3s;
  width: 95%;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
  margin-left: 3px;
  margin-bottom: 10px;

}

#videoCard:hover {
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

figure.my-stamp {
    border: 1px solid #ccc;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 20px auto 20px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
}
.my-stamp img.stamp2 {
    display: inline-block;
    color: #c00;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    border: 3px solid #c00;
    background-color: rgb(240, 222, 219);
    border-radius: 50%;
    padding: 10px;
    /* スタンプアニメーションのための初期値 */
    visibility: hidden;
    -webkit-animation: sample_anime02 2s paused both;
            animation: sample_anime02 2s paused both;
}
.my-stamp-on img.stamp2 {
    -webkit-animation-play-state: running;
            animation-play-state: running;
}

@-webkit-keyframes sample_anime02 {
    0% {
        visibility: visible;
        opacity: 0;
        -webkit-transform: rotate(-30deg) scale(2.5);
                transform: rotate(-30deg) scale(2.5);
    }
    30% {
        visibility: visible;
        opacity: 0.8;
        -webkit-transform: rotate(5deg) scale(1.04);
                transform: rotate(5deg) scale(1.04);
    }
    100% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

@keyframes sample_anime02 {
    0% {
        visibility: visible;
        opacity: 0;
        -webkit-transform: rotate(-30deg) scale(2.5);
                transform: rotate(-30deg) scale(2.5);
    }
    30% {
        visibility: visible;
        opacity: 0.8;
        -webkit-transform: rotate(5deg) scale(1.04);
                transform: rotate(5deg) scale(1.04);
    }
    100% {
        visibility: visible;
        opacity: 1;
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
}

/* https: //fuuno.net/web/center/center.html */
.iconButtons_disabled__7AIu3 {
    width: 22vw;
    height: 18vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffd700;
    box-shadow: 0 1vw 0 #ffa500;
}

.iconButtons_disabled__7AIu3:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    box-shadow: 0 0 0 #ffa500;
    transition: .0s;
}

.iconButtons_selected__1I08d {
    width: 22vw;
    height: 18vw;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ffb6c1;
    box-shadow: 0 1vw 0 #ff69b4;
}

.iconButtons_selected__1I08d:active {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
    box-shadow: 0 0 0 #ff69b4;
    transition: .0s;
}

.iconButtons_rubi__KeRfp {
    font-size: 6pt;
    margin-top: -1px;
}

.menuViewer_modalWrapper__QE2u4 {
    text-align: center;
    margin-top: 1px;
    max-width: 98%;
    max-height: 70vh;
    overflow-y: scroll
}

.board_boardWrapper__1xMJQ {
    position:relative;
    width:100vw;
    height:120vw;
    margin-top: 20px;
    text-align: center;
}

.board_boardImage__CRE-A {
    position:absolute;
    top:10px;
    margin-bottom: 100px;
}

.board_boardInnerText__c1jIP {
    position: absolute;
    top: 27vw;
    color:#424242;
    text-align: center;
}


.purchasepage_fixBar__TAEYt {
    position: -webkit-sticky;
    position: sticky;
    background-color: rgba(255, 250, 240, 0.8);
    bottom: 0px;
    z-index: 300;
}

.purchasepage_title__FPyXq {
    font-weight: bold;
    color: #404040;
    font-size: 18pt;
    -webkit-text-decoration: underline 2px;
            text-decoration: underline 2px;
    -webkit-text-decoration-color:#4fd3ffc0 ;
            text-decoration-color:#4fd3ffc0 ;
    margin-top: 8px;
}

.purchasepage_simpletitle__3syEt {
    font-weight: bold;
    color: #404040;
    font-size: 18pt;

}

.purchasepage_semititle__kAdiH {
    font-weight: bold;
    color: #404040;
    font-size: 14pt;
    margin-top: 4px;
}

.purchasepage_subtext__3U4BN {
    color: #404040;
    font-size: 10pt;
    margin-top: 4px;
    padding-left: 10px;
}

.purchasepage_appliStrength__1_Fpj{
    margin-top: 30px;
    padding: 10px;
    position:relative;
}

.purchasepage_appliStrength2__3vnTq {
    margin-top: 30px;
    position: relative;
        overflow-x: hidden;
}

.purchasepage_upper__SSX-F{
    z-index: 100;
}

.purchasepage_applicircle__2DBMu {
    position: absolute;
    top:-10px;
    height:120%;
    width:120%;
    border-radius: 50%;
    background-color: rgba(255,255,147,0.5);
    z-index: -1;
    margin-left: -40%;
    overflow-x: hidden;
}

.purchasepage_applicircleRight__-6iFP {
    position: absolute;
    top: -40px;
    height: 100%;
    width: 110%;
    border-radius: 50%;
    background-color: rgba(255, 255, 147, 0.5);
    z-index: -1;
    margin-right: -30%;
}

.purchasepage_salesbackground__v6lW-{
    background-image: linear-gradient(90deg, rgb(253, 171, 170), rgba(240, 226, 226, 1));
    padding:"10px"
}

.purchasepage_underfukidashi__3fSx9{
    position: relative;
        display: inline-block;
        width:100%;
        padding-top:10px;
        padding-bottom: 10px;
        min-width: 120px;
        color: rgb(255, 255, 255);
        font-size: 18px;
        background: #3cb371;
        font-weight: bold;
}

.purchasepage_underfukidashi__3fSx9:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top: 15px solid #3cb371;
}

.purchasepage_pinkball__1n24z {
    border-radius: 50%;
    background-color: #ffb6c1;
    color: white;
    font-weight: bold;
    height: 18vw;
    width: 18vw;
    line-height: 18vw;
    margin-left: 12px;
    margin-top:12px;
}

.purchasepage_checknow__Nzdgv {
    padding:8px;
    font-size: 16pt;
    color:#424242
}



.unmemberpage_stripe__Kt0Ug {
    text-decoration: none;
    color:white;
    font-weight: bold;
}

.unmemberpage_boldtitle__Tu0Kw {
-webkit-text-decoration: underline,;
        text-decoration: underline,
}

.unmemberpage_taikenMessageBox__FZ3RZ {
    padding:6px
}

.unmemberpage_taikenMessageTop__14WU2 {
    font-size: 13pt;
    font-weight: bold;
}

.unmemberpage_linkedText__1gVCd {
    color: #4169e1;
    text-decoration: underline;
}

.unmemberpage_lp_header__32vum {
    position: absolute;
    top:0px;
    /* background-color: rgba(255, 250, 240, 0.45); */
    width:100vw;
    display:flex;
    justify-content: flex-end;
}

.unmemberpage_backgroundImage__3QyNY {
    position: relative;
}

.unmemberpage_backgroundImage__3QyNY img {
    width: 100%;
}


.unmemberpage_programExplanation__3Ahvr {
    background-color: #daeaff;
}

.unmemberpage_sectionTitle__3E2OP {
    font-weight: bold;
    font-size: 1.4rem;
    color: '#3a567c';
    -webkit-text-decoration: underline 4px #000080;
            text-decoration: underline 4px #000080;
}

.unmemberpage_schedule__AdptC {
    font-weight: bold;
    font-size: 1.3rem;
    color: '#3a567c';
    -webkit-text-decoration: underline 4px #000080;
            text-decoration: underline 4px #000080;
}

.unmemberpage_fixBar__34tCM {
    position: -webkit-sticky;
    position: sticky;
    background-color: rgba(255,250,240,0.8);
    bottom:0px;
}

.menutextDecoration_subtitle__1jTE- {
    font-weight: bold;
    font-size: 10pt;
    color: #121212;
}

.menutextDecoration_subtitle_center__3VPJP {
    font-weight: bold;
    font-size: 10pt;
    color: #121212;
    text-align: center;
}

.menutextDecoration_content__2BQpp {
    font-size: 10pt;
    color: #424242;
}

.menutextDecoration_caution__SwOMl {
    font-size: 8pt;
    color: #424242;
}

.menutextDecoration_title_important__H5LMX {
    font-weight: bold;
    color: #ff1493;
    text-align: center;
    font-size: 10pt;
}

.practiceCarousel_blackBorder__38fbV {
    margin-top: 10vh;
    padding:12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #424242 solid
}

.practiceCarousel_joyboyWrapper__1gcqC {
    margin:auto;
    width:80vw;
    height:60vw;
}

.practiceCarousel_blueButton__10z3u {
    width:80vw;
    background-color: #0000ff;
    border-radius: 10px;
    padding:12px 0 12px 0;
}

.practiceCarousel_blackBorderSmall__2_h-1 {
    padding: 12px;
    font-size: 12pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #424242 solid
}

.practiceCarousel_yellowBorder__3pcS1 {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ffd700 solid;
    color:#ffbf00;
}

.practiceCarousel_orangeBorder__831s7 {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ff9d00 solid;
    color: #ff9d00;
}

.practiceCarousel_redBorder__1xL2e {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ff6a00 solid;
    color: #ff6200;
}

.practiceCarousel_modalContentWrapper__23s5n {
    width:90vw;
    height:60vh;
    overflow-y: scroll;
}

.fukidashi_fukidashi__3EHsI {
    border-radius: 5px;
    background-color: #4169e1;
    color:white;
    font-weight: bold;
    text-align: left;
    font-size: 9pt;
    padding:4px;
    width:30vw;
    margin-left: 32vw;
    margin-top: 11px;
    position: relative;
}

.fukidashi_fukidashi__3EHsI::before {
    content: '';
    position: absolute;
    left: 6vw;
    top: -10px;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #4169e1;
    border-left: 10px solid transparent;
}

.fukidashi_fukidashi2__2MKp5 {
    border-radius: 5px;
    background-color: #4169e1;
    color: white;
    font-weight: bold;
    text-align: left;
    font-size: 9pt;
    padding: 4px;
    width: 30vw;
    margin-left: 3vw;
    margin-top: 11px;
    position: relative;
}

.fukidashi_fukidashi2__2MKp5::before {
    content: '';
    position: absolute;
    left: 12vw;
    top: -10px;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #4169e1;
    border-left: 10px solid transparent;
}

.trial_inputwrapper__RGo-0 {
    background: #75a9ff;
    color: #fff;
    cursor: pointer;
    padding: 10px 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
}

.trial_labelinput__1So1C {
    display: none;
}

.trialpage_shojoWrapper__3Kfii {
    position: relative;
    align-items: center;
    width: 100vw;
    height: 70vw;
    color:#424242
}

.trialpage_shojoIllust__1Ymgv {
    position: absolute;
    top: 5px;
    width: 100%;
    height: auto}

.trialpage_header__1TJUT {
    position: absolute;
    margin-top: 16vw;
    width: 100%;
}

.trialpage_devider__3LrnL {
    width: 80vw;
    border: 1px solid #a9a9a9 ;
    margin-left: 10vw;
    margin-top: 10px;
}

.trialpage_content__1VSsw {
    width: 76%;
    margin-left: 12vw;
    font-size: 10pt;
}

.trialpage_ending__33zpy {
    margin-left: 50vw;
    font-size: 10pt;
}

.gradeSheet_sheet__7xeTA {
    border-radius: 16px;
    border:solid #1e90ff 3px;
    max-width: 96vw;
}

.gradeSheet_upperSection__18N-v {
background: linear-gradient(to bottom, #87cefa, #e0ffff);
border-radius: 10px 10px 0 0;
}

.gradeSheet_horizontalLine__-qz3n {
    background-color: #ffffff;
    border: solid 4px #ffffff;
    border-radius: 4px;
    width:90%;
}



.gradeSheet_currentGrade__3e4dm {
    border-radius: 50%;
    width:7vw;
    height: 7vw;
    margin:0.5vw;
    border: solid #ff0000 1px;
    background: linear-gradient(to right, #ffb6c1, #ff1493);
    color:white;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}

.gradeSheet_undoneGrade__3kV50 {
    border-radius: 50%;
    width: 7vw;
    height: 7vw;
    margin: 0.5vw;
    border: solid #d3d3d3 1px;
    background:linear-gradient(to right, #f5f5f5, #a9a9a9);
    color: white;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}

.gradeSheet_completedGrade__2hBBt {
    border-radius: 60%;
    width: 7vw;
    height: 7vw;
    margin: 0.5vw;
    background-color: #ffd700;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}

.gradeSheet_nextKey__2t-mm {
    text-align: center;
    font-size: 24pt;
    font-weight: bold;
    color:#1e90ff;
    /* -webkit-text-stroke: 0.5px #FFF;
    text-stroke: 0.5px #FFF */
}

.gradeSheet_nextThumbnail__3qiBA {
    width:40vw;
    height:40vw;
    border-radius: 20vw;
    text-align: center;
    border: solid white 2px

}

.onboardingCarousel_blackBorder__3AFlx {
    margin-top: 4vh;
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #424242 solid;
    color:#424242
}

.onboardingCarousel_joyboyWrapper__7SnTf {
    margin: auto;
    width: 80vw;
    height: 60vw;
}

.onboardingCarousel_blueButton__35G9w {
    width: 80vw;
    background-color: #0000ff;
    border-radius: 10px;
    padding: 12px 0 12px 0;
}

.onboardingCarousel_blackBorderSmall__2ggKw {
    padding: 12px;
    font-size: 12pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #424242 solid
}

.onboardingCarousel_yellowBorder__1XdX9 {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ffd700 solid;
    color: #ffbf00;
}

.onboardingCarousel_orangeBorder__l-1mo {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ff9d00 solid;
    color: #ff9d00;
}

.onboardingCarousel_redBorder__2geXk {
    padding: 12px;
    font-size: 14pt;
    font-weight: bold;
    border-radius: 20px;
    border: 3px #ff6a00 solid;
    color: #ff6200;
}

.onboardingCarousel_modalContentWrapper__3hlkB {
    width: 90vw;
    height: 60vh;
    overflow-y: scroll;
}

.guidanceTools_scrollableWrapper__lBX5T {
    overflow-x: scroll;
    margin:10vw 0 4vw 0
}

.guidanceTools_shouldBeTouched__2H98y {
    flex-shrink: 0;
    width:30vw;
    height:25vw;
    text-align: center;
    border-radius: 50%;
    line-height: 30vw;
    background-color: #fff650;
    -webkit-animation: guidanceTools_expand__YiFf1 2s linear infinite;
            animation: guidanceTools_expand__YiFf1 2s linear infinite;
    box-shadow: 0 8px 0 #d7cf41;
}

.guidanceTools_shouldBeTouched__2H98y:active {
    -webkit-transform: translateY(4px) scale(0.9);
            transform: translateY(4px) scale(0.9);
    box-shadow: 0 0 0 #ffc642;
    position: relative;
    transition-duration: 0.04s;
    top:8px;
}

.guidanceTools_disabled__2GsJk {
    width: 22vw;
    height: 19vw;
    flex-shrink: 0;
    line-height: 18vw;
    text-align: center;
    border-radius: 50%;
    background-color: #d4d4d4;
    box-shadow: 0 6px 0 #b1b1b1;
}

.guidanceTools_disabled__2GsJk:active {
    box-shadow: 0 0 0 #8e8e8e;
    position: relative;
    top: 4px;
}

.guidanceTools_canTouch__38LqA {
    width: 22vw;
    line-height: 19vw;
    height: 19vw;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    background-color: #c6e2ff;
    box-shadow: 0 6px 0 #9db4cb
}

.guidanceTools_canTouch__38LqA:active {
    box-shadow: 0 0 0 #8e8e8e;
    position: relative;
    top: 4px;
}


.guidanceTools_paymentTouch__1zsa1 {
    width: 22vw;
    line-height: 22vw;
    height: 22vw;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    background-color: #fcadc7;
    /* animation: expand 1.2s linear infinite; */
}

.guidanceTools_notebookPosition__2VILI {
    margin: 8vw 0 3vw 12vw;
}

.guidanceTools_treasureBoxPosition__3Rajl {
    margin: 8vw 0 3vw 12vw;
}

.guidanceTools_dumbbellPosition__awxaC {
    margin: 5vw 0 3vw 34vw;
}

.guidanceTools_crownPosition__1hz97 {
    margin: 5vw 0 3vw 56vw;
}

.guidanceTools_loupePosition__1W3Z4 {
    margin: 8vw 0 3vw 66vw;
}

.guidanceTools_letterPosition__3pEBV {
    margin: 5vw 0 30vw 44vw;
}

.guidanceTools_treasureBoxExplanation__3Ho-B {
    margin: 5vw 0 0 4vw;
    width:90px;
    padding:4px;
    height:6vw;
    background-color: #fff42b;
    position:relative;
    border-radius: 5px;
    font-size: 10pt;
    -webkit-animation: guidanceTools_shake__2_aZv 2s linear infinite;
            animation: guidanceTools_shake__2_aZv 2s linear infinite;
}

.guidanceTools_treasureBoxExplanation__3Ho-B::before {
    content:"";
    position: absolute;
    border-right: 17px solid #fff42b;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    width: 0;
    height: 0;
    right:95%;
    top:20%
}

.guidanceTools_dumbellExplanation__2Pmcp {
    margin: 5vw 0 0 4vw;
    width: 80px;
    padding: 4px;
    height: 6vw;
    background-color: #fff42b;
    position: relative;
    border-radius: 5px;
    font-size: 10pt;
    -webkit-animation: guidanceTools_shake__2_aZv 2s linear infinite;
            animation: guidanceTools_shake__2_aZv 2s linear infinite;
}

.guidanceTools_dumbellExplanation__2Pmcp::before {
    content: "";
    position: absolute;
    border-right: 17px solid #fff42b;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    width: 0;
    height: 0;
    right: 95%;
    top: 20%
}


.guidanceTools_next__1McEp {
    width: 24vw;
    line-height: 24vw;
    height: 24vw;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    background-color: #c6e2ff;
}

.guidanceTools_treasureBox__2KsUc {
    padding-top: 4vw;
}

.guidanceTools_imgContent__lcft4 {
    padding-top: 4vw;
}

.guidanceTools_iconContent__1MlQj {
    padding-top: 2vw;}

.guidanceTools_iconLarge__1tjUu {
    padding-top: 4vw;
}

.guidanceTools_content__3uH8F:active{
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    transition-duration: 0.3s;
}


@-webkit-keyframes guidanceTools_expand__YiFf1 {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }

    20% {
        -webkit-transform: scale(1.01);
                transform: scale(1.01);
    }

    30% {
        -webkit-transform: scale(0.98);
                transform: scale(0.98);
    }

    60% {
        -webkit-transform: scale(1.01);
                transform: scale(1.01);
    }

    70% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
    }

    100% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
}


@keyframes guidanceTools_expand__YiFf1 {
    0% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }

    20% {
        -webkit-transform: scale(1.01);
                transform: scale(1.01);
    }

    30% {
        -webkit-transform: scale(0.98);
                transform: scale(0.98);
    }

    60% {
        -webkit-transform: scale(1.01);
                transform: scale(1.01);
    }

    70% {
        -webkit-transform: scale(1.0);
                transform: scale(1.0);
    }

    100% {
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
    }
}

@-webkit-keyframes guidanceTools_shake__2_aZv {
    10% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    25% {
        -webkit-transform: translateX(-2px);
                transform: translateX(-2px);
    }

    75% {
        -webkit-transform: translateX(2px);
                transform: translateX(2px);
    }

    90% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes guidanceTools_shake__2_aZv {
    10% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    25% {
        -webkit-transform: translateX(-2px);
                transform: translateX(-2px);
    }

    75% {
        -webkit-transform: translateX(2px);
                transform: translateX(2px);
    }

    90% {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.guidanceTools_next__1McEp:active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    transition-duration: 0.3s;
}

.guidanceTools_popover__DS09C {
    padding:8px;
    font-size: 12pt;
    max-width: 70vw;
    position: relative;
    display: inline-block;
}
.guidanceTools_popover__DS09C:before {
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -15px;
    border: 15px solid #d1d1d1;
    border-bottom: 15px solid #d1d1d1;
}

.announceBar_announceBarWrapper__29qrr {
    width:100%;
}

.announceBar_announceBar__1RFXy {
    width: 90vw;
    margin:16px 3vw 16px 3vw;
    text-align: left;
    font-size: 12pt;
    border-radius: 10px;
    padding:2vw;
    line-height: 26px;
    display: flex;
    align-items: flex-end;
}

.announceBar_blackBorderSmall__3cx2J {
    padding: 8px;
    font-size: 10pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #606060 solid;
    color:#424242
}

.fab {
    position: fixed; /* 今の位置を基準 */
    bottom: 80px; /* 上から10px */
    right: 8%; /* 左から20px */
}

.modal-overlay.clicked {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
  /* 一番下のbody領域をぼやかす */
  z-index: 5;
}

.modal-content.clicked {
  z-index: 10;
}

.plus {
    border-radius: 30px;
    width: 50px;
    height: 50px;
    border-color: transparent;
    background-color: #7fbfff;
    box-shadow: 1px 2px 2px 2px rgba(188,211,255,0.8);
}

.plus:active {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}
.plus.clicked {
    background-color: #a4c1d7;
    transition-duration: 0.4s;
}

.plus.clicked::after {
    background-color: #a4c1d7;
    transition-duration: 0.4s;
}

.buttons {
    position: fixed; /* 今の位置を基準 */
    bottom: 150px; /* 上から10px */
    right: 8%; /* 左から20px */
    display: none;
}

.buttons.clicked {
    position: fixed; /* 今の位置を基準 */
    bottom: 150px; /* 上から10px */
    right: 5%; /* 左から20px */
    display: block;
    -webkit-animation: SlideIn 0.4s;
            animation: SlideIn 0.4s;
}

.add-video {
    border-radius: 30px;
    width: 150px;
    height: 50px;
    border-color: transparent;
    background-color: #87ceeb;
    color: white;
    box-shadow: 0 3px 0 #6495ed;
}

.add-video:active {
    background-color: #87ceeb;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}

.try-levelup {
  border-radius: 30px;
  width: 150px;
  height: 50px;
  border-color: transparent;
  background-color: #ff69b4;
  color: white;
  box-shadow: 0 3px 0 #ff1493;
}

.try-levelup:active {
  background-color: #ff69b4;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  transition-duration: 0.4s;
}

.trial {
    border-radius: 30px;
    width: 150px;
    height: 50px;
    border-color: transparent;
    background-color: #3cb371;
    color: white;
    box-shadow: 0 3px 0 #008000;
}

.trial:active {
    background-color: #ff69b4;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}

.workout {
    border-radius: 30px;
    width: 150px;
    height: 50px;
    border-color: transparent;
    background-color: #f39800;
    color: white;
    box-shadow: 1px 3px 0 #ee7800;
}

.workout:active {
    background-color: #ee7800;
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    transition-duration: 0.4s;
}

@-webkit-keyframes SlideIn {
    0% {
        opacity: 0; /*初期状態では透明に*/
        -webkit-transform: translateX(64px);
                transform: translateX(64px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

@keyframes SlideIn {
    0% {
        opacity: 0; /*初期状態では透明に*/
        -webkit-transform: translateX(64px);
                transform: translateX(64px);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }
}

.footer_footer__yZpM- {
    position: fixed;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
    text-align: 'center';
    background-color: #ffffff;
    bottom: 0;
    padding-top: 6px;
    padding-bottom: 18px;
    z-index: 100;
    height: 45px;
    border-top:solid 1px #c0c0c0 ;
}

.footer_unselectedIcon__2l7DV {
    width: 2.2em;
    color: #c0c0c0;
}

.footer_unselectedCharacter__1k0SV {
    font-size: 9pt;
    color: #c0c0c0;
    font-weight: bold,
}

.footer_selectedIcon__FZFV5 {
    width: 2.2em;
    color: #66ccff;
    font-size:26
}

.footer_selectedCharacter__87ysQ {
    font-size: 9pt;
    color: #66ccff;
    font-weight: bold,
 }


.header_headerBar__1Mp4y {
    top: 0px;
    width: 100%;
    background: linear-gradient(#cde5f7, #47aff9);
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.header_appTitle__16LL_ {
    text-align: center;
    font-weight:bolder;
    color:#474747;
    font-family: "monospace";
    margin: 0;
}

.header_mypage__1UGGU {
    display: table-cell;
    position: absolute;
    right: 10px;
    top: 6px;
    margin: 0;
}

.header_sidebar__2Kq-r {
    display: table-cell;
    position: absolute;
    margin: 0;
    left: 0;
    top: 1px;
}

HTML CSSResult Skip Results Iframe EDIT ON .menu {
    max-width: 600px;
}

.menu a {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #000;
}

label {
    display: block;
    margin: 0 0 4px 0;
    line-height: 1.3;

    cursor: pointer;
}

input {
    display: none;
}

.menu ul {
    margin: 0;
    padding: 0;
    background: #f4f4f4;
    list-style: none;
}

.menu li {
    height: 0;
    overflow: hidden;
    transition: all 0.3s;
}

#menu_bar01:checked ~ #links01 li {
    padding: 6px;
    height: 20px;
    font-size: 9pt;
    opacity: 1;
}

#name_icon {
    font-size: 10pt;
    padding: 4px;
    color: #ffffff;
    border-radius: 50%;
    background-color: lightsteelblue;
}

.naspage_explanationText__1LUNc {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
    color:#4169e1;
    line-height: 12pt;
}

.naspage_fixBar__3Lrv- {
    position: -webkit-sticky;
    position: sticky;
    background-color: rgba(255, 250, 240, 0.8);
    bottom: 0px;
    z-index: 300;
    text-align: center;
}

.fifty_check_background__30hcd {
    width: 100%;
    background-image: linear-gradient(#d1e8ff 0.5px, transparent 0),         linear-gradient(90deg, #d1e8ff 0.5px, transparent 0);
     background-size: 10px 10px;
    background-color: #ccffff;
}

.fifty_check_diagnoseButton__2PEpj{
    background: linear-gradient(#ff5151, #ff0000);
    width:50vw;
    border-radius: 10px;
    border:#dc143c solid
}

.fifty_check_innerText__1k0O8 {
    color: #ffff0f;
    font-weight: bolder;
    font-size: 24pt;
}

.fifty_check_quickWrapper__2NeOL {
    position: relative;
    background-color: white;
    width:100%;
    display: inline-block;
}

.fifty_check_quickWrapper__2NeOL:before {
    position: absolute;
    background-color: white;
    top: 100%;
    left: 50%;
    border: 15px solid transparent;
    border-top: 15px solid #e0edff;
}

.fifty_check_normalText__1iHA8 {
    color:#424242;
    font-size: 10pt;
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px 5vw 0 1vw;
    font-weight: bold;
}

.fifty_check_footer__1UUSg {
    background-color: #dcdcdc;
    text-align: center;
    font-family: Arial,
        Helvetica,
        sans-serif;
    padding: 12px;
}

.fifty_check_output__1-uLC {
    background-color: #e0ffe0;
    background-image: linear-gradient(#d1e8ff 0.5px, transparent 0), linear-gradient(90deg, #d1e8ff 0.5px, transparent 0);
        background-size: 10px 10px;
    text-align: center;
    font-family: Arial,
        Helvetica,
        sans-serif;
}



.aftercheckout_linkedText__mOBtE {
    color: #4169e1;
    text-decoration: underline;
}

.aftercheckout_backgroundImage__30Bap {
    position: relative;
}

.aftercheckout_backgroundImage__30Bap img {
    width: 100%;
}

.aftercheckout_backgroundImage__30Bap div {
    position: absolute;
    text-align: center;
    color: white;
        /*文字は白に*/
    top: 48%;
    left:6%;
}

.aftercheckout_programExplanation__3zc_E {
    background-color: #daeaff;
}

.aftercheckout_sectionTitle__343md {
    font-weight: bold;
    font-size: 1.4rem;
    color: '#3a567c';
    -webkit-text-decoration: underline 4px #000080;
            text-decoration: underline 4px #000080;
}



