.announceBarWrapper {
    width:100%;
}

.announceBar {
    width: 90vw;
    margin:16px 3vw 16px 3vw;
    text-align: left;
    font-size: 12pt;
    border-radius: 10px;
    padding:2vw;
    line-height: 26px;
    display: flex;
    align-items: flex-end;
}

.blackBorderSmall {
    padding: 8px;
    font-size: 10pt;
    font-weight: bold;
    border-radius: 20px;
    border: 2px #606060 solid;
    color:#424242
}
