th.date {
    font-size: 20;
}

td {
    border: solid 2px rgb(255, 255, 255); /* 枠線指定 */
    padding: 2px;
}

table.calendar {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

tr.dateCell {
    height: 13%;
    background-color: #bbeaff;
}

td.empty-day {
    background-color: rgb(230, 233, 235);
}

.outline {
    display: inline-block;
    color: #c00;
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    border: 2px solid #c00 !important;
    background-color: rgb(233, 231, 231);

    border-radius: 50%;
    padding: 3px;
    margin: 3px;
}

.outline-gray {
    display: inline-block;
    color: rgb(235, 248, 253);
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    border: 2px solid rgb(235, 248, 253) !important;
    border-radius: 50%;
    padding: 3px;
    margin: 3px;
}

.outline-red {
    display: inline-block;
    color: rgb(221, 48, 48);
    font-size: 8px;
    font-weight: bold;
    line-height: 1;
    border: 2px solid rgb(232, 102, 102) !important;
    border-radius: 50%;
    padding: 4px;
    margin: 3px;
}

/* 以下モーダル内に表示されるもの */

#videoCardByDay {
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
  transition: 0.3s;
  width: 95%;
  text-align: center;
  padding: 5px;
  margin-top: 10px;
  margin-left: 3px;
  margin-bottom: 10px;

}

#videoCard:hover {
  box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-4px);
}
