figure.my-stamp {
    border: 1px solid #ccc;
    position: relative;
    width: 150px;
    height: 150px;
    margin: 20px auto 20px;
    padding: 10px;
    font-size: 20px;
    text-align: center;
}
.my-stamp img.stamp2 {
    display: inline-block;
    color: #c00;
    font-size: 28px;
    font-weight: bold;
    line-height: 1;
    border: 3px solid #c00;
    background-color: rgb(240, 222, 219);
    border-radius: 50%;
    padding: 10px;
    /* スタンプアニメーションのための初期値 */
    visibility: hidden;
    animation: sample_anime02 2s paused both;
}
.my-stamp-on img.stamp2 {
    animation-play-state: running;
}

@keyframes sample_anime02 {
    0% {
        visibility: visible;
        opacity: 0;
        transform: rotate(-30deg) scale(2.5);
    }
    30% {
        visibility: visible;
        opacity: 0.8;
        transform: rotate(5deg) scale(1.04);
    }
    100% {
        visibility: visible;
        opacity: 1;
        transform: rotate(0deg);
    }
}
