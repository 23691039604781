.carousel .thumbs-wrapper {
    margin: 0px;
    overflow: hidden;
}


.MuiBox-root {
    padding: 4px !important;
}

.menuText {
    border: 1px solid #aaaaaa;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 10pt;
}

.menuText .menu-0 {
    background-color: #f6fac3;
    padding: 7px;
    min-height: 60px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
}

.menuText .menu-1 {
    background-color: #b5f7bb;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

.menuText .menu-2 {
    background-color: #81ccf7f6;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

.menuText .menu-3 {
    background-color: #b3a0f7;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

.menuText .menu-4 {
    background-color: #ddaef8;
    padding: 7px;
    border-radius: 10px;
    transition-delay: 0.2s;
    transition-duration: 1s;
    min-height: 60px;
}

/* これが最強の装飾方法！！！ */
span.MuiLinearProgress-root.MuiLinearProgress-colorPrimary.MuiLinearProgress-determinate.MuiMobileStepper-progress {
    position: relative;
    overflow: hidden;
    display: block;
    height: 4px;
    z-index: 0;
    background-color: rgb(167, 202, 237);
    width: 90%;
}
