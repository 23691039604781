.App {
    text-align: center;
}


#accountTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
}

#accountTable th {
    width: 30%;
    font-size: 10pt;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}
#accountTable td {
    width: 50%;

    font-size: 10pt;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}


#videoCard {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.12), 0 2px 3px 0 rgba(0, 0, 0, 0.22);
    transition: 0.3s;
    width: 95%;
    text-align: center;
    padding: 5px;
    margin-top: 10px;
    margin-left: 3px;
}
#videoCard:hover {
    box-shadow: 0 15px 30px -5px rgba(0, 0, 0, 0.15), 0 0 5px rgba(0, 0, 0, 0.1);
    transform: translateY(-4px);
}

#rankingTable {
    table-layout: fixed;
    border-collapse: collapse;
    width: 90%;
    padding: '15px';
}

#rankingTable th {
    width: 30%;
    font-size: 10pt;
    border-left: 3px solid slategray;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
    background-color: moccasin;
}

#rankingTable td {
    text-align: center;
    font-size: 10pt;
    border-left: 3px solid slategray;
    border-right: 3px solid slategray;
    border-top: 1px solid slategray;
    border-bottom: 1px solid slategray;
    padding: 6px;
}
