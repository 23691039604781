.explanationText {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 10pt;
    color:#4169e1;
    line-height: 12pt;
}

.fixBar {
    position: sticky;
    background-color: rgba(255, 250, 240, 0.8);
    bottom: 0px;
    z-index: 300;
    text-align: center;
}
