.headerBar {
    top: 0px;
    width: 100%;
    background: linear-gradient(#cde5f7, #47aff9);
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.appTitle {
    text-align: center;
    font-weight:bolder;
    color:#474747;
    font-family: "monospace";
    margin: 0;
}

.mypage {
    display: table-cell;
    position: absolute;
    right: 10px;
    top: 6px;
    margin: 0;
}

.sidebar {
    display: table-cell;
    position: absolute;
    margin: 0;
    left: 0;
    top: 1px;
}
