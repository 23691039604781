.sheet {
    border-radius: 16px;
    border:solid #1e90ff 3px;
    max-width: 96vw;
}

.upperSection {
background: linear-gradient(to bottom, #87cefa, #e0ffff);
border-radius: 10px 10px 0 0;
}

.horizontalLine {
    background-color: #ffffff;
    border: solid 4px #ffffff;
    border-radius: 4px;
    width:90%;
}



.currentGrade {
    border-radius: 50%;
    width:7vw;
    height: 7vw;
    margin:0.5vw;
    border: solid #ff0000 1px;
    background: linear-gradient(to right, #ffb6c1, #ff1493);
    color:white;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}

.undoneGrade {
    border-radius: 50%;
    width: 7vw;
    height: 7vw;
    margin: 0.5vw;
    border: solid #d3d3d3 1px;
    background:linear-gradient(to right, #f5f5f5, #a9a9a9);
    color: white;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}

.completedGrade {
    border-radius: 60%;
    width: 7vw;
    height: 7vw;
    margin: 0.5vw;
    background-color: #ffd700;
    font-size: 10pt;
    font-weight: bold;
    text-align: center;
}

.nextKey {
    text-align: center;
    font-size: 24pt;
    font-weight: bold;
    color:#1e90ff;
    /* -webkit-text-stroke: 0.5px #FFF;
    text-stroke: 0.5px #FFF */
}

.nextThumbnail {
    width:40vw;
    height:40vw;
    border-radius: 20vw;
    text-align: center;
    border: solid white 2px

}
