.fukidashi {
    border-radius: 5px;
    background-color: #4169e1;
    color:white;
    font-weight: bold;
    text-align: left;
    font-size: 9pt;
    padding:4px;
    width:30vw;
    margin-left: 32vw;
    margin-top: 11px;
    position: relative;
}

.fukidashi::before {
    content: '';
    position: absolute;
    left: 6vw;
    top: -10px;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #4169e1;
    border-left: 10px solid transparent;
}

.fukidashi2 {
    border-radius: 5px;
    background-color: #4169e1;
    color: white;
    font-weight: bold;
    text-align: left;
    font-size: 9pt;
    padding: 4px;
    width: 30vw;
    margin-left: 3vw;
    margin-top: 11px;
    position: relative;
}

.fukidashi2::before {
    content: '';
    position: absolute;
    left: 12vw;
    top: -10px;
    display: block;
    width: 0;
    height: 0;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #4169e1;
    border-left: 10px solid transparent;
}
