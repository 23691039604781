.watchWrapper{
    /* position: relative; */
    text-align: center;
    align-items:center;
}

.watchIllust {
    position: absolute;
    top:5px;
    width: 200px;
    height:200px
}

.watchInner {
    /* position: absolute;
    top: 50%; */
    background-color:#1e90ff;
    margin-left:25%;
    width:50%;
    padding:4px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 20px;
}


