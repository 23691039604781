.footer {
    position: fixed;
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
    text-align: 'center';
    background-color: #ffffff;
    bottom: 0;
    padding-top: 6px;
    padding-bottom: 18px;
    z-index: 100;
    height: 45px;
    border-top:solid 1px #c0c0c0 ;
}

.unselectedIcon {
    width: 2.2em;
    color: #c0c0c0;
}

.unselectedCharacter {
    font-size: 9pt;
    color: #c0c0c0;
    font-weight: bold,
}

.selectedIcon {
    width: 2.2em;
    color: #66ccff;
    font-size:26
}

.selectedCharacter {
    font-size: 9pt;
    color: #66ccff;
    font-weight: bold,
 }

