.scrollableWrapper {
    overflow-x: scroll;
    margin:10vw 0 4vw 0
}

.shouldBeTouched {
    flex-shrink: 0;
    width:30vw;
    height:25vw;
    text-align: center;
    border-radius: 50%;
    line-height: 30vw;
    background-color: #fff650;
    animation: expand 2s linear infinite;
    box-shadow: 0 8px 0 #d7cf41;
}

.shouldBeTouched:active {
    transform: translateY(4px) scale(0.9);
    box-shadow: 0 0 0 #ffc642;
    position: relative;
    transition-duration: 0.04s;
    top:8px;
}

.disabled {
    width: 22vw;
    height: 19vw;
    flex-shrink: 0;
    line-height: 18vw;
    text-align: center;
    border-radius: 50%;
    background-color: #d4d4d4;
    box-shadow: 0 6px 0 #b1b1b1;
}

.disabled:active {
    box-shadow: 0 0 0 #8e8e8e;
    position: relative;
    top: 4px;
}

.canTouch {
    width: 22vw;
    line-height: 19vw;
    height: 19vw;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    background-color: #c6e2ff;
    box-shadow: 0 6px 0 #9db4cb
}

.canTouch:active {
    box-shadow: 0 0 0 #8e8e8e;
    position: relative;
    top: 4px;
}


.paymentTouch {
    width: 22vw;
    line-height: 22vw;
    height: 22vw;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    background-color: #fcadc7;
    /* animation: expand 1.2s linear infinite; */
}

.notebookPosition {
    margin: 8vw 0 3vw 12vw;
}

.treasureBoxPosition {
    margin: 8vw 0 3vw 12vw;
}

.dumbbellPosition {
    margin: 5vw 0 3vw 34vw;
}

.crownPosition {
    margin: 5vw 0 3vw 56vw;
}

.loupePosition {
    margin: 8vw 0 3vw 66vw;
}

.letterPosition {
    margin: 5vw 0 30vw 44vw;
}

.treasureBoxExplanation {
    margin: 5vw 0 0 4vw;
    width:90px;
    padding:4px;
    height:6vw;
    background-color: #fff42b;
    position:relative;
    border-radius: 5px;
    font-size: 10pt;
    animation: shake 2s linear infinite;
}

.treasureBoxExplanation::before {
    content:"";
    position: absolute;
    border-right: 17px solid #fff42b;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    width: 0;
    height: 0;
    right:95%;
    top:20%
}

.dumbellExplanation {
    margin: 5vw 0 0 4vw;
    width: 80px;
    padding: 4px;
    height: 6vw;
    background-color: #fff42b;
    position: relative;
    border-radius: 5px;
    font-size: 10pt;
    animation: shake 2s linear infinite;
}

.dumbellExplanation::before {
    content: "";
    position: absolute;
    border-right: 17px solid #fff42b;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    width: 0;
    height: 0;
    right: 95%;
    top: 20%
}


.next {
    width: 24vw;
    line-height: 24vw;
    height: 24vw;
    flex-shrink: 0;
    text-align: center;
    border-radius: 50%;
    background-color: #c6e2ff;
}

.treasureBox {
    padding-top: 4vw;
}

.imgContent {
    padding-top: 4vw;
}

.iconContent {
    padding-top: 2vw;}

.iconLarge {
    padding-top: 4vw;
}

.content:active{
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    transition-duration: 0.3s;
}


@keyframes expand {
    0% {
        transform: rotateZ(0deg);
    }

    20% {
        transform: scale(1.01);
    }

    30% {
        transform: scale(0.98);
    }

    60% {
        transform: scale(1.01);
    }

    70% {
        transform: scale(1.0);
    }

    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes shake {
    10% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-2px);
    }

    75% {
        transform: translateX(2px);
    }

    90% {
        transform: translateX(0);
    }
}

.next:active {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    transition-duration: 0.3s;
}

.popover {
    padding:8px;
    font-size: 12pt;
    max-width: 70vw;
    position: relative;
    display: inline-block;
}
.popover:before {
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -15px;
    border: 15px solid #d1d1d1;
    border-bottom: 15px solid #d1d1d1;
}
