.registerWrapper{
    position:relative;
    text-align: center;
}

.registerPage{
    position: absolute;
    top: 1px;
}

.registerBack {
    position: absolute;
    top: 1px;
    z-index: -10;
}
