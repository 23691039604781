
.linkedText {
    color: #4169e1;
    text-decoration: underline;
}

.backgroundImage {
    position: relative;
}

.backgroundImage img {
    width: 100%;
}

.backgroundImage div {
    position: absolute;
    text-align: center;
    color: white;
        /*文字は白に*/
    top: 48%;
    left:6%;
}

.programExplanation {
    background-color: #daeaff;
}

.sectionTitle {
    font-weight: bold;
    font-size: 1.4rem;
    color: '#3a567c';
    text-decoration: underline 4px #000080;
}


