.formviewer {
    margin-top: 6px;
    font-style:italic;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 32pt;
    color:#ff1493;
}

.label{
    border-radius: 30px;
    border: 1px solid;
    width: 95%
}

.labelVeryGood {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #ff1493;
    font-weight: bold;
    color: white
}

.labelGood {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #3cb371;
    color: white;
    font-weight: bold;
}

.labelNobi {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #87cefa;
    font-weight: bold;
    color: white
}

.labelInactive {
    border-radius: 30px;
    border: 1px solid;
    width: 95%;
    background-color: #d3d3d3;
    color:white
}

.coachComment {
    border: 2px solid #e5ffe5;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 11pt;
    font-family: "M PLUS Rounded 1c";
    line-height: 28px;
    padding: 3px 3px 3px 10px ;
    background-color: #e5ffe5;
    position: relative;
}

.coachComment::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 2%;
    left: -11px;
    border-top: 11px solid transparent;
    border-right: 11px solid #e5ffe5;
    border-bottom: 11px solid transparent;
}

.muraiIcon {
    width: 95%;
    border-radius: 50%;
    /* animation: murai-speak 1.2s linear infinite; */
}

.coachComment2 {
    border: 2px solid #e5ffe5;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 11pt;
    font-family: "M PLUS Rounded 1c";
    line-height: 28px;
    padding: 3px 3px 3px 10px;
    background-color: #e5ffe5;
    position: relative;
    text-align: center;
}

.coachComment2::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 2%;
    left: -11px;
    border-top: 11px solid transparent;
    border-right: 11px solid #e5ffe5;
    border-bottom: 11px solid transparent;
}
