.coachComment {
    border: 2px solid #fafdff;
    border-radius: 10px;
    min-height: 60px;
    text-align: left;
    font-size: 11pt;
    font-family: "M PLUS Rounded 1c";
    line-height: 28px;
    padding: 3px;
    background-color: #fafdff;
    position: relative;
}

.coachComment::before {
    content: '';
    position: absolute;
    display: block;
    bottom: 2%;
    left: -11px;
    border-top: 11px solid transparent;
    border-right: 11px solid #fafdff;
    border-bottom: 11px solid transparent;
}

.kansou {
    min-height: 30px;
    border-radius: 11px;
    background-color: #fafdff;
    text-align: left;
    position: relative;
    padding: 8px;
    font-size: 12pt;
    width: 92%;
}

.kansou::before {
    content: '';
    position: absolute;
    display: block;
    top: 50%;
    right: -8px;
    border-top: 8px solid transparent;
    border-left: 8px solid #fafdff;
    border-bottom: 8px solid transparent;
}

.muraiIcon{
    width:95%;
    border-radius: 50%;
    animation: murai-speak 1.2s linear infinite;
}

@keyframes  murai-speak {
    0% {
        transform: rotateZ(0deg);
    }

    20% {
        transform: scale(1.1);
    }

    30% {
        transform: scale(0.9);
    }
    60% {
            transform: scale(1.1);
        }
        70% {
            transform: scale(1.0);
        }
    100% {
        transform: rotateZ(0deg);
         }
}
