.inputwrapper {
    background: #75a9ff;
    color: #fff;
    cursor: pointer;
    padding: 10px 10px;
    margin: 0 10px 0 0;
    display: inline-block;
    border-radius: 10px 10px 10px 10px;
}

.labelinput {
    display: none;
}
